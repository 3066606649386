<template>
    <div class="BigBox">
        <canvas id="myCanvas"></canvas>
        <!-- 建筑展示 -->
        <div class="JianZhuBox">
            <div class="JianZhu">
                <!-- 顶部导航 -->
                <div class="TopNav">
                    <!-- 按钮 -->
                    <div class="AnNiu">
                        <img @click="GoHome" src="../../assets/CulturalHomePage/PomePage.png" alt="">
                    </div>
                    <!-- 标题 -->
                    <div class="NavTitle">
                        <div style="margin-top:0.5vh">建筑景观馆</div>
                    </div>
                    <!-- 时间 -->
                    <div class="Time">
                        <img id="IMGHome" @click="GoLogin" src="../../assets/CulturalHomePage/personal.png" alt="">

                    </div>
                </div>
                <div style="height:10%;"></div>
                <div class="JianZhuConBox" id="JianZhuConBoxOne">
                    <div class="swiper-container swiper2">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" v-for="(JianZhuList, index) in JianZhuList" :key="index" :class="{FD:ZhongJian == index}">
                                <div class="JianZhuImgBox">
                                    <img @click="GoXiangQing(index)" :src="JianZhuList.logoUrl" style="width: 100%;height: 100%;border-radius: 5px;" alt="">
                                </div>
                                <div style="width: 100%;height: 50%;z-index: 88;position: absolute;" v-show="JianZhuList.deleteTime">
                                    <div style="margin-top: 1rem;width: 100%;display: flex;justify-content: center;">
                                        <div>
                                            <img id="BottomJianTou" style="height:50%;position: relative;top: 0px;" src="../../assets/ConstructionLandscape/ButtomJianTou.png" alt="">
                                        </div>
                                    </div>
                                    <div class="JianZhuTitle">
                                        <div>{{ JianZhuList.title }}</div>
                                        <div>{{ JianZhuList.buildTime }}</div>
                                    </div>
                                    <div class="JianZhuSun">
                                        <div>
                                            <marquee direction="up" behavior="scroll" scrollamount="1" style="height:100%;line-height: 3.5 vh;text-indent: 2em;">
                                                {{ JianZhuList.propaganda }}
                                            </marquee>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height:2%"></div>
        <!-- 景区 -->
        <div class="JingQu">
            <div class="JingQuConBox" v-for="(ConList, index) in ConList" :key="index" @click="TabJianZhu(index)" :class="{ actived: active == index }">
                <img :src="imgs[index]" style="height: 50%;">
                <div>{{ ConList.scenicName }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
export default {
    data () {
        return {
            timer: null,
            // 年月日
            yeramonthday: '',
            // 时分秒
            hourminutesecond: '',
            // 周
            week: '',
            // 首页数据
            ConList: [],
            // 建筑数据
            JianZhuList: [],
            active: 0,
            documentObj: null,
            ZhongJian: 0,
            imgs: [
                require("../../assets/ConstructionLandscape/JG.png"),
                require("../../assets/ConstructionLandscape/QK.png"),
                require("../../assets/ConstructionLandscape/TG.png"),
                require("../../assets/ConstructionLandscape/XK.png"),
                require("../../assets/ConstructionLandscape/STS.png"),
            ]
        }
    },
    mounted () {
        this.GetConList()
        // this.XingKong()
        this.initSwiper()
    },
    methods: {
        // 跳转详情
        GoXiangQing (index) {
            if (this.ZhongJian == index) {
                this.$router.push({
                    name: "ConstructionXiang",
                    query: { ConstructionLandscapeID: this.JianZhuList[index].buildingId }
                })
            }
        },
        // 图片卡片化轮番图
        initSwiper () {
            setTimeout(() => {
                var swiper = new Swiper(".swiper2", {
                    effect: 'coverflow',
                    // loop: true,
                    grabCursor: true,
                    centeredSlides: true,
                    loopedSlides: 2,
                    slideToClickedSlide: true,
                    slidesPerView: "auto",
                    initialSlide: this.SwiperLength,//从第几个开始
                    paginationClickable: true,
                    preloadImages: true,
                    updateOnImagesReady: true,
                    // 鼠标滚轮滑动
                    mousewheelControl: true,
                    // 监听哪个在中间
                    on: {
                        slideChange: (swiperHere) => {
                            setTimeout(() => {
                                this.ZhongJian = swiperHere.activeIndex
                            }, 200)

                            for (let i = 0; i < this.JianZhuList.length; i++) {
                                this.JianZhuList[i].deleteTime = false
                            }
                            this.JianZhuList[swiperHere.activeIndex].deleteTime = true
                        }
                    },
                    coverflowEffect: {
                        rotate: 10,
                        stretch: 30,
                        depth: 12,
                        modifier: 3,
                        slideShadows: false,

                    },
                });
            }, 500)
        },
        // 获取数据
        GetConList () {
            var axios = require("axios");
            var config = {
                method: "get",
                url: this.$Schttp + "/vtp/app/building/buildingIndex",
                // headers: { "Content-Type": "application/json", },
            };
            axios(config).then((res) => {
                this.ConList = res.data.data
                this.JianZhuList = this.ConList[0].buildings
                this.SwiperLength = this.JianZhuList.length / 2
            }).catch(function (error) { });
        },
        // 切换建筑
        TabJianZhu (e) {
            this.JianZhuList = this.ConList[e].buildings
            this.SwiperLength = this.JianZhuList.length / 2
            this.initSwiper()
            this.active = e
        },

        // 返回个人中心
        GoLogin () {
            // 进入页面就判断是否有token  如果没有那就继续登陆 如果有就进入个人中心
            if (this.$cookies.get("token") == null) {
                this.$router.push('/login')
            } else {
                this.$router.push('/personalCenter')
            }
        },
        // 返回首页
        GoHome () {
            this.$router.push('/');
        },
        //星空特效
        XingKong () {
            var myCanvas = document.getElementById('myCanvas');
            var ctx = myCanvas.getContext("2d");
            var starlist = [];
            function init () {
                // 设置canvas区域的范围为整个页面
                myCanvas.width = window.innerWidth;
                myCanvas.height = window.innerHeight;
            };
            init();
            // 监听屏幕大小改变 重新为canvas大小赋值
            // window.onresize = init;

            var list = [];
            // 将页面上每一个小圆点的信息存在list数组中
            // 数组中的每一位是一个对象  对象中存着每一个小点的信息
            // 利用构造函数生成数组

            function Star (x, y, radius, disX, disY) {
                // 将传入的参数设置为对象的属性
                // 位置坐标
                this.x = x;
                this.y = y;
                // 半径
                this.radius = radius;
                // 变化距离
                this.disX = disX;
                this.disY = disY;
            }

            for (var i = 0; i < 800; i++) {
                // 设置位随机值
                var x = Math.random() * myCanvas.width;
                var y = Math.random() * myCanvas.height;
                var radius = Math.random() * 2;
                var disX = x - myCanvas.width / 2;
                var disY = y - myCanvas.height / 2;
                // 每一个的信息存在对象中  所有小点存在list数组中
                list.push(new Star(x, y, radius, disX, disY));
            }

            // 绘制并且运动函数
            function animate () {
                // 在每次渲染前清除一下屏幕
                ctx.clearRect(0, 0, myCanvas.width, myCanvas.height);
                // 下一次渲染  根据存放在数组中的信息进行画小星星
                for (var i = 0; i < 800; i++) {
                    // 取出每一个信息点信息
                    var a = list[i];

                    // a的坐标在改变  根据disx disy进行改变  /50改变值设置的小一点
                    a.x += a.disX / 50;
                    a.y += a.disY / 50;

                    // 如果小圆点运动到边界之外
                    if (a.x < 0 || a.y < 0 || a.x > myCanvas.width || a.y > myCanvas.height) {
                        // 重新出现一个小星星的位置
                        a.x = Math.random() * myCanvas.width;
                        a.y = Math.random() * myCanvas.height;
                        // 同时根据 当前新出现的位置设置移动距离
                        a.disX = a.x - myCanvas.width / 2;
                        a.disY = a.y - myCanvas.height / 2;
                    }

                    // 开始画每一个点
                    ctx.beginPath();
                    // 设置填充颜色
                    ctx.fillStyle = "#00F5FF";
                    // 每一小星星为一个小圆
                    ctx.arc(a.x, a.y, a.radius, 0, Math.PI * 2, false);
                    // 进行填充
                    ctx.fill();
                }
                // 不断绘制canvas  根据disx与disy的位置变化  更新坐标  视觉上位运动状态
                setTimeout(animate, 40);
            }
            animate();
        },
    },

}
</script>

<style lang="less" scoped>
* {
    padding: 0;
    margin: 0;
}
#BottomJianTou {
    animation: BottomJianTou 2s infinite; // 反反向再运行一遍
    animation-direction: alternate;
    cursor: pointer;
}

@keyframes BottomJianTou {
    0% {
        top: -1vh;
    }

    100% {
        top: 1vh;
    }
}
.FD {
    transform: scale(1.2) !important;
}
.BigBox {
    width: 100%;
    height: 100%;
    #myCanvas {
        width: 100%;
        height: 100%;
        position: absolute;
        background: radial-gradient(
            200% 100% at bottom center,
            #013990,
            #6dadfb,
            #1b2947
        );
        background: radial-gradient(
            200% 105% at top center,
            #013990 10%,
            #6dadfb 40%,
            #013990 65%,
            #1b2947
        );
        z-index: -1;
    }
    .swiper-container {
        width: 100%;
        height: 100%;
        .swiper-wrapper {
            width: 100%;
            height: 100%;
            display: flex !important;
            align-items: center;
            .swiper-slide {
                width: 25%;
                height: 90%;
                border-radius: 10px !important;
                cursor: pointer;

                .JianZhuImgBox {
                    width: 100%;
                    height: 50%;
                    z-index: -1;
                    border-radius: 5px !important;
                    box-shadow: 0 0 5px 5px rgb(231, 231, 231);
                    -webkit-box-reflect: below 0px -webkit-linear-gradient(bottom, rgba(
                                    255,
                                    255,
                                    255,
                                    0.8
                                )
                                0%, transparent 15%, transparent 100%);
                }
                .JianZhuTitle {
                    width: 95%;
                    margin: 0.5vh auto;
                    display: flex;
                    justify-content: space-between;
                    color: #3bfff9;
                    font-size: 2.5vh;
                }
                .JianZhuSun {
                    font-size: 1.5vh;
                    width: 95%;
                    margin: 0 auto;
                    display: flex;
                    // text-indent: 2em;
                    color: white;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 8;
                    overflow: hidden;
                }
            }
        }
    }
    .JianZhuBox {
        width: 100%;
        height: 80%;
        overflow: hidden;
        .JianZhu {
            width: 100%;
            height: 70%;
        }

        .TopNav {
            width: 80%;
            margin: 0 auto 3vh;
            height: 14%;
            display: flex;
            justify-content: space-between;
            // align-items: end;
            position: relative;
            .AnNiu {
                width: 7.5vh;
                height: 7vh;
                position: absolute;
                top: 3vh;
                img {
                    height: 100%;
                    width: 100%;
                    cursor: pointer;
                }
            }

            .NavTitle {
                width: 100%;
                height: 100%;
                color: rgb(221, 249, 253);
                font-size: 3vh;
                background-image: url(../../assets/ConstructionLandscape/ConstructionLandscapeNavBG.png);
                background-size: 100% 100%;
                display: flex;
                justify-content: center;
            }

            .Time {
                width: 7.5vh;
                height: 7vh;
                position: absolute;
                top: 3vh;
                right: 0;
                #IMGHome {
                    height: 100%;
                    width: 100%;
                    cursor: pointer;
                }
            }
        }

        .JianZhuConBox {
            width: 95%;
            height: 115%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .JianZhuCon {
                width: 100%;
                height: 100%;
                display: flex;
                overflow-y: hidden;
                overflow-x: auto;

                .Architecture {
                    flex-shrink: 0;
                    width: 30%;
                    height: 100%;
                    margin: 0 3vh;
                    background-image: url('../../assets/ConstructionLandscape/ArchitectureBG.png');
                    background-size: 100% 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;

                    .ArchitectureConBox {
                        width: 70%;
                        height: 80%;
                        margin-left: 4vh;
                        margin-top: 2vh;

                        .ArchitectureConImg {
                            width: 100%;
                            height: 40%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                height: 90%;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .JingQu {
        width: 95%;
        overflow-x: scroll;
        margin: 0 auto;
        display: flex;
        .JingQuConBox {
            width: 18%;
            flex-shrink: 0;
            height: 13vh;
            background-image: url('../../assets/ConstructionLandscape/BtnBg1.png');
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: 0 2vh;
            padding-left: 5.5vh;
            box-sizing: border-box;
            div {
                font-size: 3vh;
                padding-bottom: 0.5vh;
                font-weight: 600;
                background: linear-gradient(
                    to bottom,
                    #a6dafc,
                    rgb(255, 255, 255)
                );
                -webkit-background-clip: text;
                color: transparent;
                margin-left: 4vh;
            }
        }

        .JingQuConBox:hover {
            background-image: url('../../assets/ConstructionLandscape/BtnBg3.png');
            background-size: 100% 100%;
        }

        .actived {
            background-image: url('../../assets/ConstructionLandscape/BtnBg2.png');
            background-size: 100% 100%;
            div {
                // color: #2a5891;
                background: linear-gradient(
                    to bottom,
                    rgb(183, 250, 233),
                    rgb(255, 255, 255)
                );
                -webkit-background-clip: text;
                color: transparent;
            }
        }
    }

    // 滚动条设置
    @remvw: 1920 /100vw;

    ::-webkit-scrollbar {
        width: 7 / @remvw;
        /*对垂直流动条有效*/
        height: 7 / @remvw;
        /*对水平流动条有效*/
    }

    /*定义外层滚动槽*/
    ::-webkit-scrollbar-track-piece {
        border-radius: 6 / @remvw;
    }

    /*定义内层 滑块颜色、内阴影及圆角*/
    ::-webkit-scrollbar-thumb {
        border-radius: 6 / @remvw;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #8b8b8b;
        opacity: 0.3;
    }

    /*定义两端按钮的样式*/
    ::-webkit-scrollbar-button {
        display: none;
    }

    /*定义边角样式*/
    ::-webkit-scrollbar-corner {
        display: none;
    }
}
</style>